import { State } from 'ui-smartforce-settings';
import { ReportConfig } from '../Models';
import { LandingConfig } from '../Models/Landing';

const PANEL_COLLAPSED_LS_KEY = 'Smartforce.CitizenContact.SFNavPanelCollapsed';

/**
 * get the state report config
 * @param state
 * @returns
 */
export const getStateConfig = (fileName: string): Promise<ReportConfig> => {
  const url: string = `${process.env.REACT_APP_STATE_CONFIG_BASE_URL}/configurations/${fileName}`;

  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  })
    .then((results) => {
      return results.json();
    })
    .then((data) => {
      return Promise.resolve(data as ReportConfig);
    });
};

/**
 * Get the list of states available to select into the app
 * @returns
 */
export const getStatesList = (): Promise<State[]> => {
  const url: string = `${process.env.REACT_APP_STATE_CONFIG_BASE_URL}/configurations/states.json`;

  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  })
    .then((results) => {
      return results.json();
    })
    .then((data) => {
      return Promise.resolve(data as State[]);
    });
};

export function getLandingConfig(): Promise<LandingConfig> {
  const url: string = `${process.env.REACT_APP_LANDING_CONFIG_BASE_URL}/landing.json`;

  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  }).then((results) => results.json());
}

export function getSFNavPanelCollapsedState(): boolean {
  return localStorage.getItem(PANEL_COLLAPSED_LS_KEY) === 'true';
}

export function saveSFNavPanelCollapsedState(value: boolean): void {
  localStorage.setItem(PANEL_COLLAPSED_LS_KEY, value.toString());
}
