import React from 'react';
import styles from './InvitationListItem.module.scss';
import { Avatar, UserInvitation } from 'ui-smartforce-settings';
import { SFButton, SFText } from 'sfui';

function AgencyName({ name }: { name: string }): JSX.Element {
  return <span style={{ fontWeight: 500 }}>“{name}”</span>;
}

export interface InvitationListItemProps {
  invitation: UserInvitation;
  onAccept: () => void;
}

export const InvitationListItem = ({
  invitation,
  onAccept
}: InvitationListItemProps): React.ReactElement<InvitationListItemProps> => {
  return (
    <div className={styles.invitationListItem}>
      <Avatar
        className={styles.avatar}
        url={invitation.agency.badge}
        name={invitation.agency.full_name}
        size="large"
      />

      <div className={styles.info}>
        {invitation.user && (
          <SFText type="component-1">
            {invitation.user.name} has invited you to the agency{' '}
            <AgencyName name={invitation.agency.full_name} />
          </SFText>
        )}

        {!invitation.user && (
          <SFText type="component-1">
            You have been invited to the agency{' '}
            <AgencyName name={invitation.agency.full_name} />
          </SFText>
        )}

        <SFButton size="large" onClick={onAccept}>
          Accept Invitation
        </SFButton>
      </div>
    </div>
  );
};
