import { Auth0Provider } from '@auth0/auth0-react';

interface AuthProviderWithNavigateProps {
  children: React.ReactNode;
}

const AuthProviderWithNavigate: React.FC<AuthProviderWithNavigateProps> = ({
  children
}) => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_OKTA_DOMAIN as string}
      clientId={process.env.REACT_APP_OKTA_CLIENTID as string}
      authorizationParams={{
        redirect_uri: `${process.env.REACT_APP_BASE_URL}/login-verification`,
        audience: process.env.REACT_APP_OKTA_AUDIENCE as string
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProviderWithNavigate;
