import React, { useState } from 'react';
import styles from './UserInvitations.module.scss';
import { SFPaper, SFScrollable, SFSpinner, SFText } from 'sfui';
import {
  acceptInvitation,
  dispatchCustomEvent,
  UserInvitation
} from 'ui-smartforce-settings';
import { InvitationList } from './InvitationList/InvitationList';
import { useHistory } from 'react-router-dom';
import { handleError, isOfficerAlreadyWithAgency } from '../../../Helpers';
import { MAIN_INIT } from '../../../Constants/Events';

export interface UserInvitationsProps {
  invitations: UserInvitation[];
}

export const UserInvitations = ({
  invitations
}: UserInvitationsProps): React.ReactElement<UserInvitationsProps> => {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onAccept = async (invitation: UserInvitation) => {
    try {
      setIsSaving(true);
      await acceptInvitation(
        process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
        invitation.id
      );
      setIsSaving(false);
      dispatchCustomEvent(MAIN_INIT);
      history.replace('/cc');
    } catch (e) {
      console.error(`UserInvitations::AcceptInvitation`, e);
      if (isOfficerAlreadyWithAgency(e)) {
        history.replace('/user-ori-error');
      } else {
        setIsSaving(false);
        console.error('Main::getInitialData', e);
        handleError(e, history);
      }
    }
  };

  return (
    <SFScrollable
      className={styles.userInvitations}
      containerClassName={styles.scrollableContainer}
    >
      <SFPaper className={styles.card} elevation={2}>
        <div className={styles.header}>
          <h2>Agency Invitation</h2>
          <SFText type="component-2" sfColor="neutral">
            {invitations.length > 1
              ? 'If you have multiple invitations, accepting one will automatically decline all the others.'
              : 'Accept the invitation to start using the SmartForce® ecosystem.'}
          </SFText>
        </div>

        {!isSaving && (
          <InvitationList invitations={invitations} onAccept={onAccept} />
        )}
        {isSaving && (
          <div className={styles.spinner}>
            <SFSpinner size={40} />
          </div>
        )}
      </SFPaper>
    </SFScrollable>
  );
};
