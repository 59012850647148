import React, { FC, useState } from 'react';
import { getSFNavPanelCollapsedState } from '../../Services/AppService';

export type NavPanelContextState = {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const contextDefaultValues: NavPanelContextState = {
  isCollapsed: getSFNavPanelCollapsedState(),
  setIsCollapsed: () => {}
};

export const NavPanelContext =
  React.createContext<NavPanelContextState>(contextDefaultValues);

export const NavPanelProvider: FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    getSFNavPanelCollapsedState()
  );

  return (
    <NavPanelContext.Provider
      value={{
        isCollapsed,
        setIsCollapsed
      }}
    >
      {children}
    </NavPanelContext.Provider>
  );
};
