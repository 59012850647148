import React, { Fragment } from 'react';
import styles from './InvitationList.module.scss';
import { InvitationListItem } from './InvitationListItem/InvitationListItem';
import { UserInvitation } from 'ui-smartforce-settings';
import { SFDivider } from 'sfui';

export interface InvitationListProps {
  invitations: UserInvitation[];
  onAccept: (inviation: UserInvitation) => void;
}

export const InvitationList = ({
  invitations,
  onAccept
}: InvitationListProps): React.ReactElement<InvitationListProps> => {
  return (
    <div className={styles.invitationList}>
      {invitations.map((invitation, index) => (
        <Fragment key={invitation.id}>
          <InvitationListItem
            invitation={invitation}
            onAccept={() => onAccept(invitation)}
          />
          {invitations.length > 1 && index !== invitations.length - 1 && (
            <SFDivider />
          )}
        </Fragment>
      ))}
    </div>
  );
};
