import React, { useState } from 'react';
import styles from './PricingView.module.scss';
import {
  SFLink,
  SFPaper,
  SFRadio,
  SFRadioOptionsProps,
  SFScrollable
} from 'sfui';
import { Footer } from 'footer-component';
import {
  ThemeTypeContext,
  ANNUALLY_FEE_ANALYTICS,
  MONTHLY_FEE_ANALYTICS
} from 'ui-smartforce-settings';
import {
  PricingInformation,
  PricingInformationProps
} from './PricingInformation/PricingInformation';
import { LandingTopBar } from '../../Components/LandingTopBar/LandingTopBar';

const cycleOptions: SFRadioOptionsProps[] = [
  { label: 'Pay annually', value: 'annually' },
  { label: 'Pay monthly', value: 'monthly' }
];
const FOOTER_URL: string = `${process.env.REACT_APP_FOOTER_URL}`;

const PRICING_INFORMATION: PricingInformationProps[] = [
  {
    plan: 'Connect',
    price: {
      cost: 'Free',
      description: 'For all agencies'
    },
    color: 'blue',
    features: ['Business Card', 'Support Page', 'Roles', 'Titles'],
    selectPlanVariant: 'outlined'
  },
  {
    plan: 'Analytics',
    color: 'purple',
    price: {
      cost: `$${ANNUALLY_FEE_ANALYTICS}`,
      basis: 'x member x year'
    },
    featuresTitle: 'Everything in Connect, plus...',
    features: [
      'Create Contacts',
      'Contacts History',
      'Amend Contacts',
      'Offline Reporting',
      'Amend Officers Contacts',
      'Areas',
      'Groups',
      'Add Investigations and Complaints',
      'Monthly and Weekly reports',
      '+45 different widgets',
      'Comparison of rates between periods',
      'Mapping (Heatmaps, Patterns, Clusters and Filtered by categories)',
      'Demographics',
      'FastFacts',
      'Advanced Filtering'
    ],
    selectPlanVariant: 'contained'
  }
];

function getPlanInformation(
  information: PricingInformationProps,
  cycle: string
): PricingInformationProps {
  if (information.plan === 'Connect') return information;

  return {
    ...information,
    price: {
      cost: `$${
        cycle === 'annually' ? ANNUALLY_FEE_ANALYTICS : MONTHLY_FEE_ANALYTICS
      }`,
      basis: `x member x ${cycle === 'annually' ? 'year' : 'month'}`
    }
  };
}

export const PricingView = (): React.ReactElement<{}> => {
  const { themeType } = React.useContext(ThemeTypeContext);
  const [billingCycle, setBillingCycle] = useState<string>('annually');

  return (
    <SFPaper className={styles.pricingView}>
      <LandingTopBar />

      <SFScrollable>
        <div>
          <main className={styles.content}>
            <div className={styles.textBlock}>
              <h1 className={styles.subTitle}>Pricing and Upgrades</h1>
            </div>

            <div className={styles.pricing}>
              <div className={styles.cycleOptions}>
                {cycleOptions.map((option) => (
                  <SFRadio
                    key={option.value}
                    label={option.label}
                    checked={billingCycle === option.value}
                    onChange={(_e, checked) => {
                      if (checked) {
                        setBillingCycle(option.value);
                      }
                    }}
                  />
                ))}
              </div>

              <div className={styles.cards}>
                {PRICING_INFORMATION.map(
                  (information: PricingInformationProps) => (
                    <PricingInformation
                      key={information.plan}
                      {...getPlanInformation(information, billingCycle)}
                    />
                  )
                )}
              </div>
            </div>

            <div className={styles.helpBlock}>
              <p className={styles.help}>Do you want CitizenContact?</p>
              <SFLink
                href="/registration"
                className={styles.link}
                color="primary"
                sfSize="medium"
              >
                Start Here
              </SFLink>
            </div>
          </main>

          <Footer theme={themeType} url={FOOTER_URL} />
        </div>
      </SFScrollable>
    </SFPaper>
  );
};
