import React from 'react';
import { SFTooltip, SFTooltipProps } from 'sfui';
import { isMobile } from '../../Helpers';
export interface TextOverflowTooltipProps extends SFTooltipProps {}

const isTextOverflown = (element: HTMLElement) => {
  return element.scrollWidth > element.clientWidth;
};

export const TextOverflowTooltip = ({
  children,
  title,
  ...props
}: TextOverflowTooltipProps): React.ReactElement<TextOverflowTooltipProps> => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);
  const refItem = React.useRef<HTMLElement>();

  React.useEffect(() => {
    const container = refItem.current;

    if (container) {
      const resizeObserver = new ResizeObserver(() =>
        setIsTooltipOpen(!isTextOverflown(container))
      );

      resizeObserver.observe(container);

      return () => {
        resizeObserver.unobserve(container);
      };
    }
  }, []);

  return (
    <SFTooltip
      {...props}
      disableFocusListener
      disableTouchListener={isTooltipOpen && isMobile()}
      disableHoverListener={isTooltipOpen}
      title={title}
    >
      {React.cloneElement(children, {
        ref: refItem
      })}
    </SFTooltip>
  );
};
