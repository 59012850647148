import React, { Fragment, useContext, useEffect, useState } from 'react';
import styles from './AnalyticsWidgets.module.scss';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { SFScrollable } from 'sfui';
import { WidgetsSection } from './WidgetsSection/WidgetsSection';
import { MyWidgets } from './MyWidgets/MyWidgets';
import {
  AnalyticsConfig,
  AnalyticsFiltersForm,
  AnalyticsFiltersRequest,
  AnalyticsPeriod,
  WidgetBase
} from '../../../../Models/Widget';
import { handleError, isAnalyticsNotFound } from '../../../../Helpers';
import { Customer, CustomerContext } from 'ui-smartforce-settings';
import { MyWidgetHeader } from './MyWidgetHeader/MyWidgetHeader';
import { NoAnalyticsReady } from './NoAnalyticsReady/NoAnalyticsReady';
import { Divider } from '../../../../Components/Divider/Divider';
import { useDispatchMainAlert } from '../../../../Hooks';
import { WidgetsFilter } from './WidgetsFilter/WidgetsFilter';
import { FilterByModal } from './FilterByModal/FilterByModal';
import { AnalyticsWidgetsLoader } from './AnalyticsWidgetsLoader/AnalyticsWidgetsLoader';
import { ManageWidgetsModal } from './ManageWidgetsModal/ManageWidgetsModal';
import AnalyticsService from '../../../../Services/AnalyticsService';

function formatFilterDate(date: Date, timezone: string): string {
  const newDate = moment.tz(moment(date).format('YYYY-MM-DD'), timezone);
  return newDate.toISOString();
}

function getRequestFilters(
  filters: AnalyticsFiltersForm,
  timezone: string
): AnalyticsFiltersRequest {
  let request: AnalyticsFiltersRequest = {
    period: filters.period,
    filters: {}
  };

  if (
    filters.period === 'custom' &&
    filters.dateRange?.from &&
    filters.dateRange?.to
  ) {
    const to = moment(filters.dateRange.to).add(1, 'day').toDate();

    request.filters.general_information = {
      date: {
        from: formatFilterDate(filters.dateRange.from, timezone),
        to: formatFilterDate(to, timezone)
      }
    };
  }

  if (filters.officerOption === 'name' && filters.officer) {
    request.filters.officers = {
      officer_information: {
        id: filters.officer?.asyncObject.id
      }
    };
  } else if (filters.officerOption === 'group' && filters.group) {
    request.filters.officers = {
      officer_information: {
        groups: filters.group?.map((g) => g.asyncObject.id)
      }
    };
  }

  if (filters.area) {
    request.filters.general_information = {
      ...request.filters.general_information,
      location: { areas: filters.area.map((a) => a.asyncObject.id) }
    };
  }

  return request;
}

export interface AnalyticsWidgetsProps {}

export const AnalyticsWidgets = (
  props: AnalyticsWidgetsProps
): React.ReactElement<AnalyticsWidgetsProps> => {
  const history = useHistory();
  const customer = useContext(CustomerContext).customer as Customer;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDoneFetching, setIsDoneFetching] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isNotAnalyticsReady, setIsNotAnalyticsReady] =
    React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = React.useState<string>('');
  const [modalWidgetList, setModalWidgetList] = React.useState<WidgetBase[]>(
    []
  );
  const [period, setPeriod] = React.useState<AnalyticsPeriod>();

  const [favourites, setFavourites] = useState<number[]>([]);
  const [widgetLiteList, setWidgetLiteList] = useState<WidgetBase[]>([]);
  const [suggestedWidgets, setSuggestedWidgets] = useState<WidgetBase[]>([]);
  const [newWidgets, setNewWidgets] = useState<WidgetBase[]>([]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<AnalyticsFiltersForm>({
    period: 'monthly',
    officerOption: 'name'
  });

  useDispatchMainAlert();

  useEffect(() => {
    // Declare local storage change event listener variable
    // Declared here to remove it on useEffect clean function
    let storageEventListener: {
      (e: StorageEvent): Promise<void>;
    };

    setIsLoading(true);
    setIsDoneFetching(false);
    setIsNotAnalyticsReady(false);

    const getWidgets = async () => {
      try {
        const favourites = await AnalyticsService.getFavourites();

        const config: AnalyticsConfig = await AnalyticsService.updateConfig(
          getRequestFilters(filters, customer.timezone)
        );
        const suggested = AnalyticsService.getSuggestions();
        const newWidgets = AnalyticsService.getNewWidgets();

        setFavourites(favourites);
        setWidgetLiteList(AnalyticsService.getWidgetListLite());
        setSuggestedWidgets(suggested);
        setNewWidgets(newWidgets);

        // Assign storage event handler to check changes of widget favourites
        storageEventListener = async (e: StorageEvent) => {
          // Update widgets if favourites has changed in another instance (window, tab)
          if (AnalyticsService.isWidgetStorageEvent(e)) {
            const favourites = AnalyticsService.getLSFavourites();
            setFavourites(favourites);
          }
        };

        // Add event listener to window
        window.addEventListener('storage', storageEventListener);

        setPeriod({
          name: filters.period,
          from: config.date_from,
          to: config.date_to
        });

        setIsDoneFetching(true);
        setTimeout(() => setIsLoading(false), 3000);
      } catch (e) {
        console.log('Analytics::getConfig', e);
        if (isAnalyticsNotFound(e)) {
          setIsNotAnalyticsReady(true);
          setIsLoading(false);
        } else {
          handleError(e, history);
        }
      }
    };

    getWidgets();

    return () => {
      // If exists, remove the listener from window
      if (storageEventListener) {
        window.removeEventListener('storage', storageEventListener);
      }
    };
  }, [history, customer, filters]);

  const onModalOpen = (type: 'manage' | 'new' | 'suggestions') => {
    let widgets: WidgetBase[] = [];

    switch (type) {
      case 'manage':
        widgets = widgetLiteList;
        break;
      case 'new':
        widgets = newWidgets;
        break;
      case 'suggestions':
      default:
        widgets = suggestedWidgets;
    }

    setModalWidgetList(widgets);

    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onChangeFavourites = (newFavourites: number[]) => {
    setFavourites(newFavourites);

    try {
      AnalyticsService.saveFavourites(newFavourites);
    } catch (e) {
      handleError(e, history);
      console.error('AnalyticsWidgets::onChangeFavourites', e);
    }
  };

  const onManageSave = (newFavourites: number[]) => {
    onChangeFavourites(newFavourites);
    onModalClose();
  };

  const onAddWidgets = (widgetsId: number[]) => {
    onChangeFavourites([...favourites, ...widgetsId]);
    onModalClose();
  };

  const onRemove = (id: number) => {
    onChangeFavourites(favourites.filter((f) => f !== id));
    onModalClose();
  };

  const onReorder = (newFavourites: number[]) => {
    try {
      AnalyticsService.saveFavourites(newFavourites);
    } catch (e) {
      handleError(e, history);
      console.error('AnalyticsWidgets::onReorder', e);
    }
  };

  const onFiltersSubmit = (newFilters: AnalyticsFiltersForm) => {
    if (newFilters.period !== filters.period) {
      setPeriod(undefined);
    }
    setFilters(newFilters);
    setIsFiltersOpen(false);
  };

  const onFilterDelete = (key: string) => {
    setFilters((filters) => ({
      ...filters,
      [key]: undefined
    }));
  };

  return (
    <div className={styles.analyticsWidgets}>
      <FilterByModal
        isOpen={isFiltersOpen}
        filters={filters}
        onSubmit={onFiltersSubmit}
        onClose={() => setIsFiltersOpen(false)}
      />

      {isNotAnalyticsReady && <NoAnalyticsReady />}

      {!isNotAnalyticsReady && (
        <SFScrollable containerClassName={styles.scrollable}>
          <div>
            <MyWidgetHeader
              disabled={isLoading}
              onAddWidgets={() => {
                onModalOpen('manage');
                setModalTitle('Manage Widgets');
              }}
            />

            <WidgetsFilter
              period={period}
              filters={filters}
              isLoading={isLoading}
              onFilterBy={() => setIsFiltersOpen(true)}
              onFilterDelete={onFilterDelete}
            />

            <Divider className={styles.divider} />
          </div>

          {isLoading && <AnalyticsWidgetsLoader done={isDoneFetching} />}

          {!isLoading && period && (
            <Fragment>
              <ManageWidgetsModal
                title={modalTitle}
                isOpen={isModalOpen}
                widgets={modalWidgetList}
                favourites={favourites}
                onClose={onModalClose}
                onSave={onManageSave}
              />

              <MyWidgets
                draggable={!filters.officer}
                favourites={favourites}
                period={period}
                onRemove={onRemove}
                onReorder={onReorder}
              />

              <WidgetsSection
                title="Suggestions"
                widgets={suggestedWidgets}
                period={period}
                favourites={favourites}
                onSeeAll={() => {
                  onModalOpen('suggestions');
                  setModalTitle('Suggestions');
                }}
                onAdd={(id: number) => onAddWidgets([id])}
              />

              <WidgetsSection
                title="New widgets"
                widgets={newWidgets}
                period={period}
                favourites={favourites}
                onSeeAll={() => {
                  onModalOpen('new');
                  setModalTitle('New Widgets');
                }}
                onAdd={(id: number) => onAddWidgets([id])}
              />
            </Fragment>
          )}
        </SFScrollable>
      )}
    </div>
  );
};
