import React from 'react';
import styles from './LogoOkta.module.scss';
import { ReactComponent as OktaLogo } from '../../../Images/okta-logo.svg';

export const LogoOkta = (): React.ReactElement<{}> => {
  return (
    <div className={styles.logoOkta}>
      <OktaLogo />
    </div>
  );
};
