import { getUserSession, isEqualObject } from 'ui-smartforce-settings';
import {
  AnalyticsConfig,
  AnalyticsFiltersRequest,
  AnalyticsPeriodType,
  ClusterMapWidget,
  FilterMapDataType,
  FilterMapWidget,
  GradientLineWidget,
  HeatMapWidget,
  IncidentData,
  MapBaseDataType,
  MapWidget,
  PatternMapWidget,
  Widget,
  WidgetBase
} from '../Models';
import {
  WIDGET_FILTER_MAP,
  WIDGET_HEAT_MAP,
  WIDGET_CLUSTER_MAP,
  WIDGET_RATE_CARD
} from '../Constants/Analytics';
import { apiGet, apiPost } from '../Helpers';

const LOCAL_STORAGE_KEY = 'Smartforce.CitizenContact.WidgetsFavourites';

// Temporal fix CC-3258
function getTotalContactsWidget(
  from: string,
  to: string,
  widget: Widget
): Widget {
  const component = widget.component as GradientLineWidget;
  const categoriesLength = component.config.categories.length;

  let response = { ...widget };
  let categories: string[] = [];
  let data: number[] = [];
  let secondIndex = -1;

  for (let i = 0; i < categoriesLength; i++) {
    if (secondIndex === -1) {
      categories = [...categories, component.config.categories[i]];
      data = [...data, component.config.data[i]];

      if (
        i + 1 < categoriesLength &&
        +component.config.categories[i + 1] - +component.config.categories[i] >
          1
      ) {
        secondIndex++;
      }
    } else {
      categories.splice(secondIndex, 0, component.config.categories[i]);
      data.splice(secondIndex, 0, component.config.data[i]);
      secondIndex++;
    }
  }

  response.component.config = {
    categories,
    data
  };

  return response;
}

function getWidgetWithData(
  config: AnalyticsConfig,
  id: number,
  position: number,
  period?: AnalyticsPeriodType
): Widget {
  const widget: Widget = config.widgets.find(
    (widget: Widget) => widget.id === id
  ) as Widget;

  widget.position = position;

  if (widget.component.name.indexOf('_map') === -1) {
    // Temporal fix CC-3258
    if (
      period === 'weekly' &&
      widget.component.name === 'gradient_line' &&
      id === 8
    ) {
      return getTotalContactsWidget(config.date_from, config.date_to, widget);
    } else {
      return widget;
    }
  } else {
    const mapWidget: MapWidget = widget.component as MapWidget;
    const filter = mapWidget.config.filter;

    let data: IncidentData[] = config.incident_data;

    if (filter) {
      data = data.filter((value: IncidentData) => {
        if (!value[filter.key]) {
          return false;
        }

        const dataValue = value[filter.key];

        // Check if it's the special 'not null' filter
        if (filter.value === 'not null') {
          return dataValue !== null && dataValue !== undefined;
        } else {
          // Check if data is an array: string[]
          if (Array.isArray(dataValue)) {
            return dataValue.includes(filter.value);
          } else {
            return dataValue === filter.value;
          }
        }
      });
    }

    if (widget.component.name === WIDGET_FILTER_MAP) {
      const optionKey: string = widget.component.config.options_key;
      let mapData: FilterMapDataType[] = [];

      for (let incident of data) {
        let values: string[] = [];
        if (Array.isArray(incident[optionKey])) {
          values = incident[optionKey] as string[];
        } else {
          values = [incident[optionKey] as string];
        }

        for (let value of values)
          mapData.push({
            coords: incident.coords,
            incident_number: incident.incident_number,
            incident_date: incident.incident_date,
            author_name: incident.author_name,
            name: value
          });
      }

      mapWidget.config.mapData = mapData;

      return { ...widget, component: mapWidget as FilterMapWidget };
    } else {
      const mapData: MapBaseDataType[] = data.map((incident: IncidentData) => ({
        coords: incident.coords,
        incident_date: incident.incident_date,
        incident_number: incident.incident_number,
        author_name: incident.author_name
      }));

      mapWidget.config.mapData = mapData;

      if (widget.component.name === WIDGET_HEAT_MAP) {
        return { ...widget, component: mapWidget as HeatMapWidget };
      } else if (widget.component.name === WIDGET_CLUSTER_MAP) {
        return { ...widget, component: mapWidget as ClusterMapWidget };
      } else {
        // It's a 'pattern_map'
        return { ...widget, component: mapWidget as PatternMapWidget };
      }
    }
  }
}

class AnalyticsServiceClass {
  private config: AnalyticsConfig | undefined;
  private widgetListLite: WidgetBase[] = [];
  private filters: AnalyticsFiltersRequest | undefined;

  getConfig() {
    return this.config;
  }

  getWidgetListLite() {
    return this.widgetListLite;
  }

  async updateConfig(
    filters: AnalyticsFiltersRequest
  ): Promise<AnalyticsConfig> {
    if (this.config && isEqualObject(filters, this.filters)) {
      return Promise.resolve(this.config);
    } else {
      const url = `${process.env.REACT_APP_API_BASE_URL}/analytics/generate`;

      const fetchResp = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `bearer ${getUserSession().access_token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          period: filters.period,
          filters:
            Object.keys(filters.filters).length > 0
              ? filters.filters
              : undefined
        })
      });

      if (fetchResp.ok) {
        return fetchResp.json().then((config: AnalyticsConfig) => {
          let widgets: Widget[] = [];
          this.widgetListLite = [];

          config.widgets.forEach((w: Widget, index: number) => {
            widgets = [
              ...widgets,
              getWidgetWithData(config, w.id, index, filters.period)
            ];

            this.widgetListLite = [
              ...this.widgetListLite,
              {
                id: w.id,
                title: w.title,
                type: w.component.name
              }
            ];
          });

          this.config = { ...config, widgets };
          this.filters = filters;

          return config;
        });
      } else {
        const body = await fetchResp.json();
        return Promise.reject({
          code: fetchResp.status,
          text: fetchResp.statusText,
          detail: body.detail
        });
      }
    }
  }

  getWidget(id: number): Widget | undefined {
    return this.config
      ? (this.config.widgets.find((w: Widget) => w.id === id) as Widget)
      : undefined;
  }

  getWidgets(ids: number[]): Widget[] {
    if (!this.config) return [];
    else {
      return ids
        .map((id: number) => this.getWidget(id))
        .filter((w) => !!w) as Widget[];
    }
  }

  getNewWidgets(): WidgetBase[] {
    if (!this.config) return [];

    const removeSuggestions = this.widgetListLite.filter(
      (w) =>
        !this.config?.suggested_widgets.find((value: Number) => w.id === value)
    );

    const newRateCards = removeSuggestions.filter(
      (w) => w.type === WIDGET_RATE_CARD
    );

    let newWidgets = newRateCards.slice(newRateCards.length - 5);

    const newOthers = removeSuggestions.filter(
      (w) => w.type !== WIDGET_RATE_CARD
    );

    newWidgets = [...newWidgets, ...newOthers.slice(newOthers.length - 5)];

    return newWidgets;
  }

  getSuggestions(): WidgetBase[] {
    let result: WidgetBase[] = [];
    if (this.config?.suggested_widgets) {
      for (let id of this.config.suggested_widgets) {
        const widget = this.widgetListLite.find((w) => w.id === id);
        if (widget) {
          result = [...result, widget];
        }
      }
    }
    return result;
  }

  getLSFavourites(): number[] {
    const favourites = localStorage.getItem(LOCAL_STORAGE_KEY);
    return favourites ? JSON.parse(favourites) : [];
  }

  async getFavourites(): Promise<number[]> {
    const url = `${process.env.REACT_APP_API_BASE_URL}/analytics/me/settings`;
    const favourites = await apiGet<number[]>(
      url,
      getUserSession().access_token
    );
    const lsFavourites = this.getLSFavourites();

    if (lsFavourites.length > 0 && !isEqualObject(favourites, lsFavourites)) {
      this.saveFavourites(lsFavourites);
      return lsFavourites;
    } else {
      if (lsFavourites.length === 0) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(favourites));
      }
      return favourites;
    }
  }

  async saveFavourites(widgets: number[]): Promise<{ widgets: number[] }> {
    const url = `${process.env.REACT_APP_API_BASE_URL}/analytics/me/settings`;

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(widgets));
    return apiPost(url, { widgets }, getUserSession().access_token);
  }

  isWidgetStorageEvent(e: StorageEvent): boolean {
    return e.key === LOCAL_STORAGE_KEY;
  }
}

const AnalyticsService = new AnalyticsServiceClass();

export default AnalyticsService;
