import React from 'react';
import {
  WizardFinishContent,
  WizardFinishContentProps
} from './WizardFinishContent/WizardFinishContent';
import { SFButtonProps } from 'sfui';

function getTitle(type: WizardFinishType): string {
  if (type === 'payment-pending') {
    return 'Complete your payment information.';
  } else if (type.includes('payment-accepted')) {
    return 'Thank You!';
  } else {
    return 'Done!';
  }
}

function getMessage(type: WizardFinishType): string {
  switch (type) {
    case 'payment-accepted':
      return 'Your payment was accepted successfully and your agency was set up.';
    case 'upgrade-payment-accepted':
      return 'Your payment was accepted successfully.';
    case 'payment-pending':
      return 'Complete all payment information by clicking on the button below to finish the process.';
    default:
      return 'You have set up your Agency.';
  }
}

function getIcon(type: WizardFinishType): string {
  if (type === 'payment-pending') {
    return 'File-2';
  } else {
    return 'Check-1';
  }
}

function getContentProps(
  type: WizardFinishType
): Omit<WizardFinishContentProps, 'ButtonElement'> {
  return {
    icon: getIcon(type),
    title: getTitle(type),
    message: getMessage(type)
  };
}

export type WizardFinishType =
  | 'done'
  | 'payment-accepted'
  | 'payment-pending'
  | 'upgrade-payment-accepted'
  | 'upgrade-payment-pending';

export interface WizardFinishtProps {
  className?: string;
  type: WizardFinishType;
  ButtonElement: React.ReactElement<SFButtonProps>;
}

export const WizardFinish = ({
  className = '',
  type,
  ButtonElement
}: WizardFinishtProps): React.ReactElement<WizardFinishtProps> => {
  const props = getContentProps(type);

  return (
    <div className={className}>
      <WizardFinishContent {...props} ButtonElement={ButtonElement} />
    </div>
  );
};
