import React, { Fragment } from 'react';
import styles from './WidgetTypeImage.module.scss';
import {
  WIDGET_CLUSTER_MAP,
  WIDGET_HEAT_MAP,
  WIDGET_FILTER_MAP,
  WIDGET_PATTERN_MAP,
  WIDGET_DOUGHNUT,
  WIDGET_SANKEY,
  WIDGET_GRADIENT_LINE,
  WIDGET_RANKING_CARD,
  WIDGET_RATE_CARD
} from '../../../../../../../Constants/Analytics';
import {
  DMClusterMapImage,
  NMClusterMapImage,
  DMHeatMapImage,
  NMHeatMapImage,
  DMFilterMapImage,
  NMFilterMapImage,
  DMPatternMapImage,
  NMPatternMapImage,
  DMDoughnutImage,
  NMDoughnutImage,
  DMSankeyImage,
  NMSankeyImage,
  DMGradientLineImage,
  NMGradientLineImage,
  DMRankingCardImage,
  NMRankingCardImage,
  DMRateCardNumberImage,
  NMRateCardNumberImage
} from '../../../../../../../Images/Analytics/images';

function getWidgetTypeImage(
  name: string,
  isDayMode: boolean
): React.ReactElement {
  switch (name) {
    case WIDGET_CLUSTER_MAP:
      return isDayMode ? <DMClusterMapImage /> : <NMClusterMapImage />;

    case WIDGET_HEAT_MAP:
      return isDayMode ? <DMHeatMapImage /> : <NMHeatMapImage />;

    case WIDGET_FILTER_MAP:
      return isDayMode ? <DMFilterMapImage /> : <NMFilterMapImage />;

    case WIDGET_PATTERN_MAP:
      return isDayMode ? <DMPatternMapImage /> : <NMPatternMapImage />;

    case WIDGET_DOUGHNUT:
      return isDayMode ? <DMDoughnutImage /> : <NMDoughnutImage />;

    case WIDGET_SANKEY:
      return isDayMode ? <DMSankeyImage /> : <NMSankeyImage />;

    case WIDGET_GRADIENT_LINE:
      return isDayMode ? <DMGradientLineImage /> : <NMGradientLineImage />;

    case WIDGET_RANKING_CARD:
      return isDayMode ? <DMRankingCardImage /> : <NMRankingCardImage />;

    case WIDGET_RATE_CARD:
      return isDayMode ? <DMRateCardNumberImage /> : <NMRateCardNumberImage />;

    default:
      return <Fragment />;
  }
}

export interface WidgetTypeImageProps {
  type: string;
  isDayMode: boolean;
}

export const WidgetTypeImage = ({
  type,
  isDayMode
}: WidgetTypeImageProps): React.ReactElement<WidgetTypeImageProps> => {
  return (
    <div className={styles.widgetTypeImage}>
      {getWidgetTypeImage(type, isDayMode)}
    </div>
  );
};
