import React, { useContext, useEffect } from 'react';
import styles from './OktaCallback.module.scss';
import { SFSpinner } from 'sfui';
import { Auth0Context } from '@auth0/auth0-react';
import {
  removeUserInvitation,
  oktaLogin,
  saveUserSession
} from 'ui-smartforce-settings';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import {
  isUserNotExist,
  isOfficerAlreadyWithAgency,
  isOfficerIdAlreadyExist,
  isOwnerInvited,
  isUserInvitationNotExist
} from '../../Helpers';

export const OktaCallback = (): React.ReactElement<{}> => {
  const history = useHistory();
  const { user, getAccessTokenSilently } = useContext(Auth0Context);
  const { setIsLogged } = useContext(AuthContext);

  useEffect(() => {
    const init = async () => {
      try {
        if (user?.email) {
          const oktaToken = await getAccessTokenSilently();
          const userSession = await oktaLogin(
            process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
            oktaToken,
            process.env.REACT_APP_CC_VERSION as string
          );
          setIsLogged(true);
          saveUserSession(userSession);
          history.push('/cc', { fromLogin: true });
        } else {
          history.push('/login');
          console.error('OktaCallback::onInit::No email');
        }
      } catch (e) {
        if (isUserNotExist(e)) {
          history.push('/complete-your-information', { user });
        } else if (isOwnerInvited(e)) {
          console.error(
            `OktaCallback::AcceptInvitation::OwnerUsersCanNotBeInvited`,
            e
          );
          removeUserInvitation();
          history.push('/cc');
        } else if (isUserInvitationNotExist(e)) {
          // CC-1009: Redirect user to onboarding view as a new officer with no agency assigned yet.
          console.error(
            `OktaCallback::AcceptInvitation::UserInvitationNotExist`,
            e
          );
          removeUserInvitation();
          history.push('/cc/onboarding');
        } else if (isOfficerIdAlreadyExist(e)) {
          console.error(
            `OktaCallback::AcceptInvitation::OfficerIdAlreadyExists`,
            e
          );
          history.push('/user-id-error');
        } else if (isOfficerAlreadyWithAgency(e)) {
          console.error(
            `OktaCallback::AcceptInvitation::isOfficerAlreadyWithAgency`,
            e
          );
          history.push('/user-ori-error');
        } else {
          console.error(`OktaCallback::GeneralError`, e);
          history.push('/error');
        }
      }
    };

    init();
  }, [history, user, setIsLogged, getAccessTokenSilently]);

  return (
    <div className={styles.oktaCallback}>
      <SFSpinner size={42} />
    </div>
  );
};
