import React from 'react';
import styles from './Brand.module.scss';
import { ReactComponent as SFBrand } from '../../Images/SF_logo.svg';
import { useHistory } from 'react-router-dom';
import { InteractiveBox } from 'ui-smartforce-settings';

export interface BrandProps {
  className?: string;
}

export const Brand = ({
  className = ''
}: BrandProps): React.ReactElement<BrandProps> => {
  const history = useHistory();

  const onClick = () => {
    history.replace('/');
  };

  return (
    <h1 style={{ margin: 0 }}>
      <InteractiveBox
        className={`${styles.brand} ${className} `}
        aria-label="Go to home"
        onClick={onClick}
      >
        <SFBrand />
      </InteractiveBox>
    </h1>
  );
};
