import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

export const RouteAuthGuard = ({ children, ...rest }: RouteProps) => {
  const { isLogged } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      children={() => (isLogged === true ? children : <Redirect to="/login" />)}
    />
  );
};
