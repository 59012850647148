import React, { useEffect, useRef, useState } from 'react';
import styles from './Masonry.module.scss';
import { MasonryElementProps } from './MasonryElement/MasonryElement';

interface MasonryBaseProps {
  isNavPanelCollapsed: boolean;
  children: React.ReactElement<MasonryElementProps>[];
  draggable?: boolean;
}

interface MasonryNonDraggableProps extends MasonryBaseProps {
  draggable: false;
}

interface MasonryDraggableProps extends MasonryBaseProps {
  draggable: true;
  id: string;
  onUpdateOrder?: (indexes: number[]) => void;
}

const getInitialOrder = (length: number): number[] => {
  let order = [];
  for (let i = 0; i < length; i++) {
    order.push(i);
  }
  return order;
};

const getRemovedNewOrder = (order: number[], removed: number): number[] => {
  let newOrder: number[] = [];
  for (let num of order) {
    if (num === removed) {
      continue;
    } else if (num > removed) {
      num = num - 1;
    }

    newOrder.push(num);
  }

  return newOrder;
};

const getAddedNewOrder = (order: number[], newLength: number): number[] => {
  let newOrder: number[] = [...order];
  for (let i = order.length; i < newLength; i++) {
    newOrder.push(i);
  }

  return newOrder;
};

const getChildrenIds = (
  children: React.ReactElement<MasonryElementProps>[]
): number[] => {
  return children.map(
    (c: React.ReactElement<MasonryElementProps>) => c.props.id
  );
};

export type MasonryProps = MasonryNonDraggableProps | MasonryDraggableProps;

export const Masonry = ({
  isNavPanelCollapsed,
  children,
  ...props
}: MasonryProps): React.ReactElement<MasonryProps> => {
  const [elementsOrder, setElementsOrder] = useState<number[]>(
    getInitialOrder(children.length)
  );

  const refPrevChildrenIds = useRef<number[]>(getChildrenIds(children));

  useEffect(() => {
    const newLength = children.length;
    const oldLength = refPrevChildrenIds.current.length;
    const newChildrenIds: number[] = getChildrenIds(children);

    if (refPrevChildrenIds.current && oldLength !== newLength) {
      if (oldLength > newLength) {
        const removedIndex: number = refPrevChildrenIds.current.findIndex(
          (id: number) => newChildrenIds.indexOf(id) === -1
        );
        setElementsOrder((order) => getRemovedNewOrder(order, removedIndex));
      } else {
        setElementsOrder((order) => getAddedNewOrder(order, newLength));
      }
    }

    refPrevChildrenIds.current = newChildrenIds;
  }, [children]);

  const refStartIndex = useRef<number>(-1);

  const onDragStart = (
    startIndex: number,
    e: React.DragEvent<HTMLDivElement>
  ) => {
    if (props.draggable) {
      e.dataTransfer.setData(props.id.toString(), '');
      refStartIndex.current = startIndex;
    }
  };

  const onDrop = (dropIndex: number, _e: React.DragEvent<HTMLDivElement>) => {
    const startIndex = refStartIndex.current;

    if (dropIndex !== startIndex) {
      const newOrder = [...elementsOrder];
      const dragItemOrder = newOrder[startIndex];
      newOrder.splice(startIndex, 1);
      newOrder.splice(dropIndex, 0, dragItemOrder);
      refStartIndex.current = -1;

      setElementsOrder(newOrder);
      props.draggable && props.onUpdateOrder && props.onUpdateOrder(newOrder);
    }
  };

  return (
    <div
      className={`${styles.masonry} ${
        isNavPanelCollapsed ? styles.isNavPanelCollapsed : ''
      }`}
    >
      {elementsOrder
        .filter((n: number) => !!children[n])
        .map((n: number) => children[n])
        .map(
          (child: React.ReactElement<MasonryElementProps>, index: number) => {
            if (!props.draggable) return child;

            return React.cloneElement(child, {
              draggable: true,
              masonryId: props.id,
              onDragStart: (e: React.DragEvent<HTMLDivElement>) =>
                onDragStart(index, e),
              onDrop: (e: React.DragEvent<HTMLDivElement>) => onDrop(index, e)
            });
          }
        )}
    </div>
  );
};
