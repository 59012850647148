import React, { useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import styles from './SFNavPanel.module.scss';
import { SFDrawer, SFIconButton } from 'sfui';
import SFNavContent from './SFNavContent/SFNavContent';
import { SFNavLink, SFNavLinkRoute } from './SFNavLink';
import { ReactComponent as CitizenContactBrand } from '../../Images/cc_brands-simple.svg';
import { OnlineStatusContext } from '../../Context/OnlineStatusContext';
import { isOfflinePath } from '../../Helpers';
import { saveSFNavPanelCollapsedState } from '../../Services/AppService';
import { NavPanelContext } from '../../Context/NavPanelContext';

function getLink(links: SFNavLink[], pathname: string): SFNavLink {
  let current: SFNavLink | undefined = links.find(
    (link: SFNavLink) => link.type === 'route_internal' && link.to === pathname
  );

  return current ? current : links[0];
}

export interface SFNavPanelProps {
  className?: string;
  links: SFNavLink[];
  isBigScreen: boolean;
  isOpen: boolean;
  onLinkSelected: (link: SFNavLink) => void;
  onClose?: () => void;
}

const SFNavPanel = ({
  className,
  links,
  isBigScreen,
  isOpen,
  onLinkSelected,
  onClose
}: SFNavPanelProps): React.ReactElement<SFNavPanelProps> => {
  const location = useLocation();
  const { isOnline } = useContext(OnlineStatusContext);
  const { isCollapsed, setIsCollapsed } = useContext(NavPanelContext);

  const linkSelected = useMemo((): SFNavLinkRoute => {
    return getLink(links, location.pathname) as SFNavLinkRoute;
  }, [links, location.pathname]);

  const onDrawerClose = () => {
    onClose && onClose();
  };

  const onLinkChange = (
    e: React.MouseEvent<HTMLAnchorElement>,
    link: SFNavLinkRoute
  ) => {
    if (isOnline || (!isOnline && isOfflinePath(link.to))) {
      onDrawerClose();
    }

    if (
      location.pathname === link.to ||
      (!isOnline && !isOfflinePath(link.to))
    ) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      if (link.type === 'route_internal') {
        onLinkSelected(link);
      }
    }
  };

  const onToggleCollapse = () => {
    setIsCollapsed((c) => !c);
    saveSFNavPanelCollapsedState(!isCollapsed);
  };

  return (
    <div className={`${styles.sfNavPanel} ${className || ''}`}>
      {isBigScreen && (
        <div className={styles.content}>
          <SFNavContent
            links={links}
            isPanel={false}
            selected={linkSelected}
            isCollapsed={isCollapsed}
            onToggleCollapse={onToggleCollapse}
            onLinkChange={onLinkChange}
          />
        </div>
      )}

      {!isBigScreen && (
        <div className={styles.panelWrapper}>
          <SFDrawer
            className={styles.navPanelDrawer}
            open={isOpen}
            PaperProps={{
              className: styles.navPanelDrawerPaper
            }}
            onClose={onDrawerClose}
          >
            <div className={styles.topBar}>
              <CitizenContactBrand className={styles.ccBrand} />
              <div className={styles.buttons}>
                <SFIconButton
                  sfIcon="Close"
                  sfSize="medium"
                  onClick={onDrawerClose}
                />
              </div>
            </div>

            <SFNavContent
              links={links}
              isPanel={true}
              selected={linkSelected}
              onLinkChange={onLinkChange}
              onClose={onDrawerClose}
            />
          </SFDrawer>
        </div>
      )}
    </div>
  );
};

export default SFNavPanel;
