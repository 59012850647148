import React, { useContext } from 'react';
import styles from './SFNavContentLink.module.scss';
import { Link } from 'react-router-dom';
import { SFChip, SFIcon } from 'sfui';
import { SFNavLink } from '../../SFNavLink';
import { InteractiveBox } from 'ui-smartforce-settings';
import { NavPanelContext } from '../../../../Context/NavPanelContext';

export interface SFNavContentLinkProps {
  link: SFNavLink;
  disabled?: boolean;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const SFNavContentLink = ({
  link,
  disabled = false,
  selected,
  onClick
}: SFNavContentLinkProps): React.ReactElement<SFNavContentLinkProps> => {
  const { isCollapsed } = useContext(NavPanelContext);

  const getContent = () => (
    <React.Fragment>
      <SFIcon icon={link.icon} className={styles.icon} size={20} />
      {!isCollapsed && <span className={styles.text}>{link.label}</span>}
      {!isCollapsed && link.chip && (
        <span className={styles.chip}>
          <SFChip sfColor="primary" label={link.chip} size="small" />
        </span>
      )}
    </React.Fragment>
  );

  return (
    <div
      className={`${styles.linkContainer} ${disabled ? styles.disabled : ''}`}
    >
      <div
        className={`${styles.linkSelected} ${selected ? styles.selected : ''}`}
      ></div>
      {link.type === 'route_internal' && (
        <Link
          to={link.to}
          className={`${styles.link} ${selected ? styles.isSelected : ''}`}
          onClick={onClick}
        >
          {getContent()}
        </Link>
      )}

      {link.type === 'route_external' && (
        <a
          href={link.to}
          className={styles.link}
          onClick={onClick}
          target="_blank"
          rel="noreferrer"
        >
          {getContent()}
        </a>
      )}

      {link.type === 'event' && (
        <InteractiveBox className={styles.link} onClick={link.onClick}>
          {getContent()}
        </InteractiveBox>
      )}
    </div>
  );
};

export default SFNavContentLink;
