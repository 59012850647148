export const WIDGET_CLUSTER_MAP = 'cluster_map';
export const WIDGET_DOUGHNUT = 'doughnut';
export const WIDGET_FILTER_MAP = 'filter_map';
export const WIDGET_GRADIENT_LINE = 'gradient_line';
export const WIDGET_HEAT_MAP = 'heat_map';
export const WIDGET_PATTERN_MAP = 'pattern_map';
export const WIDGET_RANKING_CARD = 'ranking_card';
export const WIDGET_RATE_CARD = 'rate_card';
export const WIDGET_SANKEY = 'sankey';

export const WIDGET_TYPES = [
  WIDGET_RATE_CARD,
  WIDGET_DOUGHNUT,
  WIDGET_FILTER_MAP,
  WIDGET_GRADIENT_LINE,
  WIDGET_RANKING_CARD,
  WIDGET_CLUSTER_MAP,
  WIDGET_SANKEY,
  WIDGET_PATTERN_MAP,
  WIDGET_HEAT_MAP
];
