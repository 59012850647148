import React from 'react';
import styles from './SFNavContentBrand.module.scss';
import { ReactComponent as SFBadge } from '../../../../Images/ui_smartforce_badge.svg';

export interface SFNavContentBrandProps {
  isOnline: boolean;
  isCollapsed: boolean;
}

export const SFNavContentBrand = ({
  isOnline,
  isCollapsed
}: SFNavContentBrandProps): React.ReactElement<SFNavContentBrandProps> => {
  return (
    <div className={styles.sFNavContentBrand}>
      {isCollapsed && (
        <a
          className={!isOnline ? styles.isOffline : ''}
          href={process.env.REACT_APP_SMARTFORCE_SITE_URL}
          target="_blank"
          rel="noreferrer"
        >
          <SFBadge className={styles.sfBadge} />
        </a>
      )}

      {!isCollapsed && (
        <>
          <p className={styles.version} aria-hidden="true">
            Version {process.env.REACT_APP_CC_VERSION}
          </p>

          <a
            className={!isOnline ? styles.isOffline : ''}
            href={process.env.REACT_APP_SMARTFORCE_SITE_URL}
            target="_blank"
            rel="noreferrer"
          >
            <span>Powered by</span>
            <br />
            <span className={styles.semibold}>
              SmartForce Technologies, Inc.
            </span>
          </a>
        </>
      )}
    </div>
  );
};
