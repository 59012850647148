import React, { useContext, useState } from 'react';
import styles from './CompleteYourInformationView.module.scss';
import { Auth0Context, User as OktaUser } from '@auth0/auth0-react';

import { SFTextField, SFCheckbox, SFLink, SFButton, SFText } from 'sfui';
import {
  OFFICER_ID_INVALID_MSG,
  isOfficerIdValid,
  isFieldEmpty,
  oktaRegister,
  saveUserSession
} from 'ui-smartforce-settings';
import { AuthContainer } from '../../Components/AuthContainer/AuthContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import { handleError } from '../../Helpers';

function checkFormValid(officerId: string, checkPolicies: boolean): boolean {
  return !isFieldEmpty(officerId) && checkPolicies;
}

interface CompleteYourInformationViewState {
  user: OktaUser;
}

export interface CompleteYourInformationViewProps {}

export const CompleteYourInformationView = (
  props: CompleteYourInformationViewProps
): React.ReactElement<CompleteYourInformationViewProps> => {
  const history = useHistory();
  const { getAccessTokenSilently } = useContext(Auth0Context);
  const { setIsLogged } = useContext(AuthContext);

  const location = useLocation();
  const locationState: CompleteYourInformationViewState =
    location.state as CompleteYourInformationViewState;

  const [officerId, setOfficerId] = React.useState<string>('');
  const [checkPolicies, setCheckPolicies] = React.useState<boolean>(false);
  const [isOfficerIdError, setIsOfficerIdError] =
    React.useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSave = async () => {
    const checkOfficerId: boolean = isOfficerIdValid(officerId);
    setIsOfficerIdError(!checkOfficerId);

    if (checkOfficerId) {
      try {
        setIsSaving(true);
        const oktaToken = await getAccessTokenSilently();
        const userSession = await oktaRegister(
          process.env.REACT_APP_SETTINGS_API_BASE_URL as string,
          oktaToken,
          officerId,
          process.env.REACT_APP_CC_VERSION as string
        );
        setIsSaving(false);
        setIsLogged(true);
        saveUserSession(userSession);
        history.replace('/cc', { fromLogin: true });
      } catch (e) {
        setIsSaving(false);
        console.error('CompleteYourInformationView::onSave', e);
        handleError(e, history);
      }
    }
  };

  return (
    <AuthContainer>
      <div className={styles.completeYourInformationView}>
        <SFText type="component-title">Complete your information</SFText>

        <div className={styles.content}>
          <div className={styles.form}>
            <SFTextField
              label="Full Name"
              disabled
              value={locationState.user.name}
            />

            <SFTextField
              label="Officer ID Number"
              value={officerId}
              error={isOfficerIdError}
              helperText={OFFICER_ID_INVALID_MSG}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOfficerId(e.target.value)
              }
            />

            <SFTextField
              label="E-mail"
              type="email"
              disabled
              value={locationState.user.email}
            />

            <div className={styles.checkPolicies}>
              <SFCheckbox
                label={
                  <React.Fragment>
                    I have read and accept the SmartForce®{' '}
                    <SFLink
                      sfSize="small"
                      color="primary"
                      target="_blank"
                      href="https://smartforcetech.com/#/agreement-citizencontact"
                    >
                      Privacy Policies.
                    </SFLink>
                  </React.Fragment>
                }
                checked={checkPolicies}
                onChange={(
                  _e: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => setCheckPolicies(checked)}
              />
            </div>
          </div>

          <SFButton
            size="large"
            fullWidth
            disabled={!checkFormValid(officerId, checkPolicies)}
            onClick={onSave}
            isLoading={isSaving}
          >
            Save Changes
          </SFButton>
        </div>
      </div>
    </AuthContainer>
  );
};
