import React, { useContext } from 'react';
import styles from './WidgetsSection.module.scss';
import { AnalyticsPeriod, WidgetBase } from '../../../../../Models';
import { WidgetList } from '../../../../../Components/WidgetList/WidgetList';
import { WidgetsSectionHeader } from './WidgetsSectionHeader/WidgetsSectionHeader';
import { MasonryElementSize } from '../../../../../Components/Masonry/MasonryElement/MasonryElement';
import { getWidgetColSize } from '../../../../../Helpers';
import { WIDGET_RATE_CARD } from '../../../../../Constants/Analytics';
import { MediaContext } from 'ui-smartforce-settings';
import AnalyticsService from '../../../../../Services/AnalyticsService';
import { Divider } from '../../../../../Components/Divider/Divider';
import { NavPanelContext } from '../../../../../Context/NavPanelContext';

function getColumnsCount(
  isPhone: boolean,
  isLargeScreen: boolean,
  isExtraLargeScreen: boolean,
  isCollapsed: boolean
): number {
  let columns = 2;
  if (isPhone) {
    columns = 1;
  } else if (isExtraLargeScreen || (isCollapsed && isLargeScreen)) {
    columns = 3;
  }

  return columns;
}

const getColSize = (name: string, columns: number): number => {
  const size: MasonryElementSize = getWidgetColSize(name);

  if (columns === 1 || size === 'xs' || size === 'sm') {
    return 1;
  } else if (size === 'md') {
    return 2;
  } else {
    return columns;
  }
};

const getVisibleWidgets = (
  widgets: WidgetBase[],
  favourites: number[],
  columns: number
): number[] => {
  let result: WidgetBase[] = [];
  let countSmall = 0;
  let othersColSum = 0;

  const widgetsWithoutFavourites = widgets.filter(
    (w) => !favourites.includes(w.id)
  );

  for (let widget of widgetsWithoutFavourites) {
    // Stop looping when there are max rate cards in the first row
    // and no more place to put other widgets in the second row
    if (countSmall === columns && othersColSum === columns) {
      break;
    }

    if (widget.type === WIDGET_RATE_CARD) {
      if (countSmall < columns) {
        countSmall++;
        result = [...result, widget];
      }
    } else {
      othersColSum += getColSize(widget.type, columns);
      if (othersColSum <= columns) {
        result = [...result, widget];
      }
    }
  }

  return result.map((w) => w.id);
};

export interface WidgetsSectionProps {
  title: string;
  widgets: WidgetBase[];
  period: AnalyticsPeriod;
  favourites: number[];
  onSeeAll: () => void;
  onAdd: (id: number) => void;
}

export const WidgetsSection = ({
  title,
  widgets,
  period,
  favourites,
  onSeeAll,
  onAdd
}: WidgetsSectionProps): React.ReactElement<WidgetsSectionProps> => {
  const { isPhone, isLargeScreen, isExtraLargeScreen } =
    useContext(MediaContext);
  const { isCollapsed } = useContext(NavPanelContext);

  const columns = getColumnsCount(
    isPhone,
    isLargeScreen,
    isExtraLargeScreen,
    isCollapsed
  );

  const visibleWidgets = AnalyticsService.getWidgets(
    getVisibleWidgets(widgets, favourites, columns)
  );

  return visibleWidgets.length > 0 ? (
    <>
      <Divider />
      <div className={styles.widgetsSection}>
        <WidgetsSectionHeader title={title} onSeeAll={onSeeAll} />
        <WidgetList period={period} widgets={visibleWidgets} onAdd={onAdd} />
      </div>
    </>
  ) : (
    <></>
  );
};
