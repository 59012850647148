import React, { useEffect, useState } from 'react';
import styles from './ManageWidgetsModal.module.scss';
import { WidgetBase } from '../../../../../Models';
import { WIDGET_TYPES } from '../../../../../Constants/Analytics';
import { PanelModal } from 'ui-smartforce-settings';
import {
  WidgetTypeForm,
  WidgetTypeFormValue
} from './WidgetTypeForm/WidgetTypeForm';

function isSameFavourites(a: number[], b: number[]): boolean {
  return [...a].sort().toString() === [...b].sort().toString();
}
interface WidgetListSelectionValue {
  name: string;
  widgets: WidgetTypeFormValue[];
}

function getGroupedWidgets(widgets: WidgetBase[]): WidgetListSelectionValue[] {
  let result: WidgetListSelectionValue[] = [];

  for (let type of WIDGET_TYPES) {
    const widgetsByType = widgets
      .filter((w) => w.type === type)
      .map((w) => ({
        id: w.id,
        label: w.title
      }));

    if (widgetsByType.length > 0) {
      result = [
        ...result,
        {
          name: type,
          widgets: widgetsByType
        }
      ];
    }
  }

  return result;
}

export interface ManageWidgetsModalProps {
  title: string;
  isOpen: boolean;
  widgets: WidgetBase[];
  favourites: number[];
  onClose: () => void;
  onSave: (newFavourites: number[]) => void;
}

export const ManageWidgetsModal = ({
  title,
  isOpen,
  widgets,
  favourites,
  onClose,
  onSave
}: ManageWidgetsModalProps): React.ReactElement<ManageWidgetsModalProps> => {
  const [favouritesFormValue, setFavouritesFormValue] =
    useState<number[]>(favourites);

  useEffect(() => {
    setFavouritesFormValue(favourites);
  }, [favourites]);

  const onChange = (id: number, checked: boolean) => {
    setFavouritesFormValue((oldValue) => {
      if (checked) {
        return [...oldValue, id];
      } else {
        return oldValue.filter((fid) => fid !== id);
      }
    });
  };

  return (
    <PanelModal
      classes={{
        dialog: {
          root: styles.dialog,
          paper: styles.dialogPaper,
          container: styles.dialogContainer
        }
      }}
      title={title}
      isOpen={isOpen}
      dialogCloseButton={{
        label: 'Discard',
        variant: 'text',
        sfColor: 'grey',
        onClick: onClose
      }}
      actionButton={{
        label: 'Save Changes',
        disabled: isSameFavourites(favourites, favouritesFormValue),
        onClick: () => onSave(favouritesFormValue)
      }}
      onClose={onClose}
    >
      <div className={styles.manageWidgetsModal}>
        {getGroupedWidgets(widgets).map((w) => (
          <WidgetTypeForm
            key={`widget-type-${w.name}`}
            type={w.name}
            widgets={w.widgets}
            value={favouritesFormValue}
            onChange={onChange}
          />
        ))}
      </div>
    </PanelModal>
  );
};
